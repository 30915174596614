import React, { useState, useEffect } from "react";
import { firestore } from "../../services/firebase";
import {
  collection,
  orderBy,
  query,
  onSnapshot,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";

const Content = () => {
  const collectionname = "content";
  const [contents, setContents] = useState([]);
  const [subtopicdetails, setSubtopicDetails] = useState(null);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.contentid && params.contentid.length > 0) {
      GetSubTopicDetail();
      GetAllContents();
    }
  }, [params.contentid]);

  async function GetAllContents() {
    const data = await query(
      collection(firestore, collectionname),
      where("subtopicid", "==", params.contentid),
      orderBy("ordseq", "asc")
    );
    onSnapshot(data, (querySnapshot) => {
      const databaseInfo = [];

      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.docid = doc.id;
        databaseInfo.push(data);
      });
      setContents(databaseInfo);
    });
  }

  async function GetSubTopicDetail() {
    const docRef = doc(firestore, "subtopics", params.contentid);
    await getDoc(docRef)
      .then((snapshot) => {
        setSubtopicDetails(snapshot.data());
      })
      .catch((e) => console.log(e));
  }

  function OpenContantHeading(viewid) {
    navigate("/view/" + viewid);
  }
  return (
    <React.Fragment>
      <ul class="bgcard w-100 my-4">
        <lh>
          <h2 className="mainheadertext">
            {subtopicdetails && subtopicdetails.subtopicname}
          </h2>
        </lh>
        <hr />
        {contents && contents.length > 0 ? (
          contents.map((topic) => {
            return (
              <li
                class="d-flex align-items-center justify-content-between w-100 mb-4"
                onClick={(e) => OpenContantHeading(topic.docid)}
              >
                <div class="d-flex align-items-center justify-content-center">
                  <img
                    src="/assets/icons/topics.png"
                    alt="Book"
                    class="chapterlogo"
                  />
                  <h3 className="chaptertext m-0">{topic.heading}</h3>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                  <img
                    src="/assets/icons/right-arrow.png"
                    alt="Book"
                    class="chapterlogo"
                  />
                </div>
              </li>
            );
          })
        ) : (
          <li class="d-flex align-items-center justify-content-center w-100 mb-4">
            <h2 className="chaptertext m-0 text-danger">No Contents Available</h2>
          </li>
        )}
      </ul>
    </React.Fragment>
  );
};

export default Content;
