import React, { useState, useEffect } from "react";
import { firestore } from "../../services/firebase";
import {
  collection,
  orderBy,
  query,
  onSnapshot,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "../../services/useLocalStorage";

const Favourite = () => {
  const collectionname = "content";
  const [contents, setContents] = useState([]);
  const [favourites, setFavourites] = useLocalStorage("myfavourites", "");
  const navigate = useNavigate();

  useEffect(() => {
    GetAllContents();
  }, []);

  async function GetAllContents() {
    const data = await query(
      collection(firestore, collectionname),
      where("uuid", "in", favourites.split(",")),
      orderBy("created", "asc")
    );
    onSnapshot(data, (querySnapshot) => {
      const databaseInfo = [];

      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.docid = doc.id;
        databaseInfo.push(data);
      });
      setContents(databaseInfo);
    });
  }

  function OpenContantHeading(viewid) {
    navigate("/view/" + viewid);
  }

  async function AddToFavourite(uuid) {
    let myfavourites = favourites.split(",");
    myfavourites.push(uuid);
    setFavourites(myfavourites.join(","));
  }

  async function RemoveToFavourite(uuid) {
    let myfavourites = favourites.split(",").filter((x) => x != uuid);
    setFavourites(myfavourites.join(","));
  }
  return (
    <React.Fragment>
      <ul class="bgcard w-100 my-4">
        <lh>
          <h2 className="mainheadertext">My Favourite</h2>
        </lh>
        <hr />
        {contents && contents.length > 0 ? (
          contents.map((topic) => {
            return (
              <li class="d-flex align-items-center justify-content-between w-100 mb-4">
                <div
                  class="d-flex align-items-center justify-content-center"
                  onClick={(e) => OpenContantHeading(topic.docid)}
                >
                  <img
                    src="/assets/icons/chapters.png"
                    alt="Book"
                    class="chapterlogo"
                  />
                  <h3 className="chaptertext m-0">{topic.heading}</h3>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                  {favourites && favourites.split(",").includes(topic.uuid) ? (
                    <i
                      class="fa fa-heart text-danger"
                      onClick={(e) => RemoveToFavourite(topic.uuid)}
                    ></i>
                  ) : (
                    <i
                      class="fa fa-heart text-dark"
                      onClick={(e) => AddToFavourite(topic.uuid)}
                    ></i>
                  )}
                </div>
              </li>
            );
          })
        ) : (
          <li class="d-flex align-items-center justify-content-center w-100 mb-4">
            <h2 className="chaptertext m-0 text-danger">
              No Details Available
            </h2>
          </li>
        )}
      </ul>
    </React.Fragment>
  );
};

export default Favourite;
