import React from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
const Header = () => {
  const navigate = useNavigate();

  function BackButton() {
    return (
      <i
        className="fa fa-arrow-circle-left fa-2x pointerevent"
        onClick={(e) => navigate(-1)}
      />
    );
  }
  return (
    <React.Fragment>
      <div className="d-flex headermenu w-100">
        <div className="d-flex align-items-center justify-content-between mx-4 w-100">
          <div
            onClick={(e) => navigate("/")}
            className="pointerevent d-flex align-items-center"
          >
            <Routes>
              <Route path="/subtopics/:subtopicid" element={<BackButton />} />
              <Route path="/contents/:contentid" element={<BackButton />} />
              <Route path="/view/:viewid" element={<BackButton />} />
              <Route path="/favourites" element={<BackButton />} />
            </Routes>
            <img src="/assets/icons/logo.png" className="logo" />
          </div>
          <div>
            <h1 className="headertext">Aamale Muktasar</h1>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
