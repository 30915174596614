import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home, SubTopic, Content, View, Favourite } from "./Pages/Pages";
import Layout from "./components/Layout/Layout";

const App = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/subtopics/:subtopicid" element={<SubTopic />} />
          <Route path="/contents/:contentid" element={<Content />} />
          <Route path="/view/:viewid" element={<View />} />
          <Route path="/favourites" element={<Favourite />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default App;
