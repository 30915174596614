import React, { useState, useEffect } from "react";
import { firestore } from "../../services/firebase";
import { collection, orderBy, query, onSnapshot } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const collectionname = "topics";
  const [topics, setTopics] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    GetAllTopics();
  }, []);

  async function GetAllTopics() {
    const data = await query(
      collection(firestore, collectionname),
      orderBy("ordseq", "asc")
    );
    onSnapshot(data, (querySnapshot) => {
      const databaseInfo = [];

      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.docid = doc.id;
        databaseInfo.push(data);
      });
      setTopics(databaseInfo);
    });
  }

  function OpenSubTopic(subtopicid) {
    navigate("/subtopics/" + subtopicid);
  }

  return (
    <ul class="bgcard w-100 my-4">
      <lh>
        <h2 className="mainheadertext">Chapters</h2>
      </lh>
      <hr />
      {topics && topics.length > 0 ? (
        topics.map((topic) => {
          return (
            <li
              class="d-flex align-items-center justify-content-between w-100 mb-4"
              onClick={(e) => OpenSubTopic(topic.docid)}
            >
              <div class="d-flex align-items-center justify-content-center">
                <img
                  src="assets/icons/chapters.png"
                  alt="Book"
                  class="chapterlogo"
                />
                <h3 className="chaptertext m-0">{topic.topicname}</h3>
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <img
                  src="assets/icons/right-arrow.png"
                  alt="Book"
                  class="chapterlogo"
                />
              </div>
            </li>
          );
        })
      ) : (
        <li class="d-flex align-items-center justify-content-center w-100 mb-4">
          <h2 className="chaptertext m-0 text-danger">No Details Available</h2>
        </li>
      )}
    </ul>
  );
};

export default Home;
