import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div class="d-flex bottommenu w-100">
      <div class="flex-1 d-flex align-items-center justify-content-center">
        <i
          class="fa fa-home fa-2x pointerevent"
          onClick={() => navigate("/")}
        ></i>
      </div>
      <div class="flex-1 d-flex align-items-center justify-content-center">
        <i
          class="fa fa-heart fa-2x pointerevent"
          onClick={() => navigate("/favourites")}
        ></i>
      </div>
      <div class="flex-1 d-flex align-items-center justify-content-center">
        <i class="fa fa-share-alt fa-2x pointerevent"></i>
      </div>
    </div>
  );
};

export default Footer;
