import React, { useState, useEffect } from "react";
import { firestore } from "../../services/firebase";
import { useLocalStorage } from "../../services/useLocalStorage";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";

const View = () => {
  const collectionname = "content";
  const [content, setContentDetails] = useState(null);
  const navigate = useNavigate();
  const params = useParams();
  const [favourites, setFavourites] = useLocalStorage("myfavourites", "");

  useEffect(() => {
    if (params.viewid && params.viewid.length > 0) {
      GetContentDetail();
    }
  }, [params.viewid]);

  async function GetContentDetail() {
    const docRef = doc(firestore, collectionname, params.viewid);
    await getDoc(docRef)
      .then((snapshot) => {
        setContentDetails(snapshot.data());
      })
      .catch((e) => console.log(e));
  }

  async function AddToFavourite() {
    let myfavourites = favourites.split(",");
    myfavourites.push(content.uuid);
    setFavourites(myfavourites.join(","));
  }

  async function RemoveToFavourite() {
    let myfavourites = favourites.split(",").filter((x) => x != content.uuid);
    setFavourites(myfavourites.join(","));
  }

  return (
    <React.Fragment>
      <div class="row d-flex align-items-center justify-content-end w-100">
        {favourites &&
        content &&
        favourites.split(",").includes(content.uuid) ? (
          <i class="fa fa-heart text-danger" onClick={RemoveToFavourite}></i>
        ) : (
          <i class="fa fa-heart text-dark" onClick={AddToFavourite}></i>
        )}
        {/* &nbsp;&nbsp;&nbsp;&nbsp;
        <i class="fa fa-share-alt text-dark"></i> */}
      </div>
      <div className="card">
        <div className="card-body">
          <h1 className="h1">{content && content.heading}</h1>
          <hr />
          {content && (
            <div
              dangerouslySetInnerHTML={{ __html: content.content }}
              style={{ overflow: "wrap", textAlign: "justify" }}
            ></div>
          )}
        </div>
        <br />
        <br />
      </div>
    </React.Fragment>
  );
};

export default View;
