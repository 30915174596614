import React, { useState, useEffect } from "react";
import { firestore } from "../../services/firebase";
import {
  collection,
  orderBy,
  query,
  onSnapshot,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";

const SubTopic = () => {
  const collectionname = "subtopics";
  const [subtopics, setSubTopics] = useState([]);
  const [topicdetails, setTopicDetaills] = useState(null);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.subtopicid && params.subtopicid.length > 0) {
      GetTopicDetail();
      GetAllSubTopics();
    }
  }, [params.subtopicid]);

  async function GetAllSubTopics() {
    const data = await query(
      collection(firestore, collectionname),
      where("topicid", "==", params.subtopicid),
      orderBy("ordseq", "asc")
    );
    onSnapshot(data, (querySnapshot) => {
      const databaseInfo = [];

      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.docid = doc.id;
        databaseInfo.push(data);
      });
      setSubTopics(databaseInfo);
    });
  }

  async function GetTopicDetail() {
    const docRef = doc(firestore, "topics", params.subtopicid);
    await getDoc(docRef)
      .then((snapshot) => {
        setTopicDetaills(snapshot.data());
      })
      .catch((e) => console.log(e));
  }

  function OpenContantHeading(contentid) {
    navigate("/contents/" + contentid);
  }
  return (
    <React.Fragment>
      <ul class="bgcard w-100 my-4">
        <lh>
          <h2 className="mainheadertext">
            {topicdetails && topicdetails.topicname}
          </h2>
        </lh>
        <hr />
        {subtopics && subtopics.length > 0 ? (
          subtopics.map((topic) => {
            return (
              <li
                class="d-flex align-items-center justify-content-between w-100 mb-4"
                onClick={(e) => OpenContantHeading(topic.docid)}
              >
                <div class="d-flex align-items-center justify-content-center">
                  <img
                    src="/assets/icons/topics.png"
                    alt="Book"
                    class="chapterlogo"
                  />
                  <h3 className="chaptertext m-0">{topic.subtopicname}</h3>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                  <img
                    src="/assets/icons/right-arrow.png"
                    alt="Book"
                    class="chapterlogo"
                  />
                </div>
              </li>
            );
          })
        ) : (
          <li class="d-flex align-items-center justify-content-center w-100 mb-4">
            <h2 className="chaptertext m-0 text-danger">
              No Topics Available
            </h2>
          </li>
        )}
      </ul>
    </React.Fragment>
  );
};

export default SubTopic;
